import { fetchDataHelper } from '../../../Common/Helpers/DataHelpers'
import {
  IKelownaSonarDownSystem,
  ISonarDownSystem,
  ISonarFilter,
  TSortOrder,
} from '../../../Common/Logic/Types'
import { downSystemsTableApplicableFilters } from '../Constants/SonarConstants'

/**
 * Formats a given date object to "hh:mm AM/PM" style using local time.
 * @param date - The Date object to be formatted.
 * @returns A string representing the formatted time.
 */
const formatTime = (date: Date): string => {
  const hours = date.getHours() // Get local hours
  const minutes = date.getMinutes() // Get local minutes
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12 // Convert hour to 12-hour format
  const strHours =
    formattedHours < 10 ? `0${formattedHours}` : `${formattedHours}` // Zero pad hours if less than 10
  const strMinutes = minutes < 10 ? `0${minutes}` : `${minutes}` // Zero pad minutes if less than 10
  return `${strHours}:${strMinutes} ${ampm}`
}

/**
 * Initialize to fetch DownSystems Table Data
 */
const fetchDownSystemsData = async (
  limit: number,
  offset: number,
  order: string | undefined,
  orderBy: string,
  sonarFilter: ISonarFilter,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  abortController: AbortController
) => {
  const sonarFilterParams = defineDownSystemsFilter(sonarFilter)

  const params = {
    Limit: limit,
    Offset: offset,
    SortColumn: orderBy,
    SortOrder: (order === 'asc' ? 'ascending' : 'descending') as TSortOrder,
    ...sonarFilterParams,
  }

  const arrayParams = {
    technologyTypes: sonarFilter.technology,
  }

  const endpoint = `/Hardware/Sonar/DownSystems/Table`
  setIsLoading(true)
  const response = await fetchDataHelper(
    endpoint,
    params,
    arrayParams,
    abortController
  )

  if (response.ok) {
    setIsLoading(false)
    return response.json()
  } else {
    console.error('Failed to fetch DownSystems data')
    setIsLoading(false)
    return { count: 0, results: [] }
  }
}

/**
 * Defines the filter for the Down Systems table.
 * @param {ISonarFilter} sonarFilter - Filter object containing filter items to be used.
 * @returns {Object} - Filter object to passed in the API call.
 */
const defineDownSystemsFilter = (sonarFilter: ISonarFilter) => {
  // As each endpoint has different filter parameter, we need to map the Sonar atom filter keys to the filter items.
  const sonafFilterParams = downSystemsTableApplicableFilters.reduce(
    (acc, { atomKey, filterKey }) => {
      const value = sonarFilter[atomKey]
      if (
        value !== undefined &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        acc[filterKey] = value
      }
      return acc
    },
    {} as Record<string, any>
  )
  return sonafFilterParams
}

/**
 * Defines the filename for the Down Systems data download.
 * @param {ISonarFilter} sonarFilter - Filter object containing filter items to be used.
 *
 */
const defineDownSystemsDataFileName = (sonarFilter: ISonarFilter) => {
  const isFilterEmpty = Object.values(sonarFilter).every(
    item =>
      item === null ||
      item === undefined ||
      (Array.isArray(item) && item.length === 0)
  )

  const currentDate = new Date()

  const padZero = (num: number) => num.toString().padStart(2, '0')

  const formattedDate = `${currentDate.getFullYear()}${padZero(
    currentDate.getMonth() + 1
  )}${padZero(currentDate.getDate())}`
  const formattedTime = `${padZero(currentDate.getHours())}${padZero(
    currentDate.getMinutes()
  )}${padZero(currentDate.getSeconds())}`
  const formattedDateTime = `${formattedDate}_${formattedTime}`

  return isFilterEmpty
    ? `down_system_log_${formattedDateTime}.csv`
    : `filtered_down_system_log_${formattedDateTime}.csv`
}

const digestDownSystemsData = (
  kelownaDownSystems: IKelownaSonarDownSystem[]
): ISonarDownSystem[] => {
  return kelownaDownSystems.map(kelownaDownSystem => {
    return {
      daysDown: kelownaDownSystem.daysDown,
      divisionId: kelownaDownSystem.divisionId,
      divisionName: kelownaDownSystem.divisionName,
      lastSeenTimestamp: kelownaDownSystem.lastSeenTimestamp,
      location: kelownaDownSystem.location,
      reclaimedWaterUnitId: kelownaDownSystem.reclaimedWaterUnitId,
      remoteUnitId: kelownaDownSystem.remoteUnitId,
      snoozeId: kelownaDownSystem.snoozeId,
      systemId: kelownaDownSystem.systemId,
      technologyType: kelownaDownSystem.technologyType,
      snoozed: kelownaDownSystem.snoozeId ? 'true' : 'false', // Removed the double negation as it is redundant
      snoozeReason: kelownaDownSystem.snoozeReason,
      snoozeReasonDetails: kelownaDownSystem.snoozeReasonDetails,
      snoozeBy: kelownaDownSystem.snoozeLastUpdatedByUserFirstAndLastName,
      snoozeDate: kelownaDownSystem.snoozeLastUpdatedTimestamp,
      snoozeEnd: kelownaDownSystem.snoozeEndTimestamp,
    }
  })
}

const downSystemsHelper = {
  defineDownSystemsDataFileName,
  defineDownSystemsFilter,
  fetchDownSystemsData,
  formatTime,
  digestDownSystemsData,
}

export default downSystemsHelper
