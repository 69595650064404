import { atom, selector } from 'recoil'
import { CommissioningFilterOptions } from '../TSS/Constants/CommonConstants'
import { atomPlantsFiltersSettings } from './tssAtoms'
import { IMaterialTypeMetadata } from '../TSS/Logic/Types'
import {
  ICurrentCustomerAtom,
  ISonarFilter,
  ISonarFrontendFilter,
} from './Logic/Types'

/*
 * The atoms.js will contain all atoms for shared state.
 * As a general rule, only define an atom if a state needs to be shared between views.
 * Try and have states that are dependent on an endpoint/return value of a function state it in the jsdocs for that atom.
 * Below are two examples of how an atom should be documented.
 */

/**
 * Atom for TSS (and possibly in the future others) to keep track of the selected customer.
 *
 * initial structure:  {division: null, plant: null}
 *
 * Current customer should be an object with the shape of/include after being set:
 * {
 *  division: {
 *     divisionId: int,
 *     name: string,
 *     isImperial: bool
 *   },
 *  plant: {
 *     plantId: int,
 *     name: string
 *  }
 * }
 */
const atomCurrentCustomer = atom<ICurrentCustomerAtom>({
  key: 'customer',
  default: {
    division: null,
    plant: null,
  },
})

const atomMixCodeQuery = atom({
  key: 'mixCode',
  default: '',
})

/** get value from plantsFilterSettings to match with URL parameters */
const metricSwitch = selector({
  key: 'metricSwitch',
  get: ({ get }) => {
    return get(atomPlantsFiltersSettings).isMetric
  },
})

const atomCommissioningFilter = atom<CommissioningFilterOptions | null>({
  key: 'atomComissioningFilter',
  default: null,
})

/** Atom that holds the user's JSON Web Token (JWT) from localStorage */
const atomJWT = atom({
  key: 'atomJWT',
  default: { firstName: '', lastName: '', roles: [] },
})

/** Atom that holds Material Manager's Metadata */
const atomMaterialMetadata = atom<Map<string, IMaterialTypeMetadata[]> | null>({
  key: 'atomMaterialMetadata',
  default: null,
})

/** Atom that holds the Sonar Filters data */
const atomSonarFilter = atom<ISonarFilter>({
  key: 'atomSonarFilters',
  default: {
    technology: ['Plc', 'Pcb', 'ReclaimedWater'],
    divisionId: [],
    plantId: [],
    systemId: [],
    region: [],
    country: [],
    state: [],
    city: [],
    lastSeenDateRange: [],
    lastSeenStartDate: [],
    lastSeenEndDate: [],
    shouldShowSnoozed: ['false'],
  },
})

/** Atom that holds the Sonar Frontend Filters data */
const atomSonarFrontendFilter = atom<ISonarFrontendFilter>({
  key: 'atomSonarFrontendFilters',
  default: {
    isUTC: ['false'],
  },
})

export {
  atomCurrentCustomer,
  atomMixCodeQuery,
  metricSwitch,
  atomCommissioningFilter,
  atomJWT,
  atomMaterialMetadata,
  atomSonarFilter,
  atomSonarFrontendFilter,
}
